<template>
  <div class="header" :class="isfixed ? 'toFixed' : ''">
    <div class="header-container">
      <div class="chLogo" :class="$i18n.locale === 'en-US' ? 'enLogo' : ''">
        <img
          :src="logo"
          alt=""
          @click="$router.push({ path: '/home' })"
          style="cursor: pointer; width: 100%; height: 100%"
        />
      </div>
      <div class="list">
        <img src="../assets/mobile_list.png" alt="" @click="toShowList" />
        <div class="popup">
          <van-popup
            v-model="listShow"
            position="right"
            closeable
            close-icon="cross"
            class="list-popup"
          >
            <div class="content">
              <div
                class="tab"
                v-for="(item, index) in $t('lang.tabs')"
                :key="index"
              >
                <span class="tab-item">
                  <router-link
                    :to="{ path: item.path }"
                    :style="{
                      color: active === item.active ? '#000' : '',
                      fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
                    }"
                  >
                    {{ item.title }}
                  </router-link>
                  <i
                    class="el-icon-arrow-down el-icon--right"
                    v-if="index === 2 && !down"
                    @click="dropDown"
                  ></i>
                  <i
                    class="el-icon-arrow-up el-icon--right"
                    v-if="index === 2 && down"
                    @click="dropDown"
                  ></i>
                </span>
                <el-collapse-transition>
                  <div class="drop-item" v-show="index === 2 && down">
                    <div @click="handleCommand('a')">
                      {{ $t("lang.headerBusiness.titleOne") }}
                    </div>
                    <div @click="handleCommand('b')">
                      {{ $t("lang.headerBusiness.titleTwo") }}
                    </div>
                    <div @click="handleCommand('c')">
                      {{ $t("lang.headerBusiness.titleThree") }}
                    </div>
                    <div @click="handleCommand('d')">
                      {{ $t("lang.headerBusiness.titleFour") }}
                    </div>
                    <div @click="handleCommand('e')">
                      {{ $t("lang.headerBusiness.titleFive") }}
                    </div>
                  </div>
                </el-collapse-transition>
              </div>
            </div>
            <div class="content" style="margin-top: 0">
              <div
                class="language"
                @click="changeLangEvent('eng')"
                style="fontfamily: 'Avenir, Helvetica, Arial, sans-serif'"
              >
                English
              </div>
              <div class="language" @click="changeLangEvent('ch')">
                中文简体
              </div>
            </div>
          </van-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chaLogo from "@/assets/logo.jpg";
import enLogo from "@/assets/eng_header.jpg";
export default {
  name: "mobileHeader",
  data() {
    return {
      activeNames: "1",
      chaLogo,
      enLogo,
      isfixed: "",
      logo: "",
      listShow: false,
      down: false,
      height: 0,
    };
  },
  created() {
    const path = this.$route.path;
    switch (path) {
      case "/home":
        this.active = 0;
        break;
      case "/aboutUs":
        this.active = 1;
        break;
      case "/commerceOperation":
        this.active = 2;
        break;
      case "/expansion":
        this.active = 3;
        break;
      case "/brandCenter":
        this.active = 4;
        break;
      case "/joinUs":
        this.active = 5;
        break;
      case "/technologyCenter":
        this.active = 2;
        break;
      case "/trade":
        this.active = 2;
        break;
      case "/supplyService":
        this.active = 2;
        break;
      case "/customerService":
        this.active = 2;
        break;
      case "/applyJoin":
        this.active = 2;
        break;
    }
    window.addEventListener("scroll", this.scrollToTop);
    const language = sessionStorage.getItem("language") || "zh-CN";
    if (language === "zh-CN") {
      this.logo = chaLogo;
    } else {
      this.logo = enLogo;
    }
  },
  methods: {
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 0) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
    },
    changeLangEvent(language) {
      this.$router.go(0);
      if (language === "eng") {
        this.$i18n.locale = "en-US"; //关键语句
        this.logo = enLogo;
        sessionStorage.setItem("language", "en-US");
      } else {
        this.$i18n.locale = "zh-CN"; //关键语句
        this.logo = chaLogo;
        sessionStorage.setItem("language", "zh-CN");
      }
      window.pageYOffset =
        document.documentElement.scrollTop =
        document.body.scrollTop =
          0;
      this.scrollTop = 0;
    },
    handleCommand(command) {
      switch (command) {
        case "a":
          this.$router.push({ path: "/commerceOperation" });
          break;
        case "b":
          this.$router.push({ path: "/trade" });
          break;
        case "c":
          this.$router.push({ path: "/technologyCenter" });
          break;
        case "d":
          this.$router.push({ path: "/supplyService" });
          break;
        case "e":
          this.$router.push({ path: "/customerService" });
          break;
        case "f":
          this.$router.push({ path: "/applyJoin" });
          break;
      }
      this.listShow = false;
      this.down = false;
    },
    toShowList() {
      this.listShow = !this.listShow;
    },
    dropDown() {
      this.down = !this.down;
    },
  },
};
</script>

<style scoped lang="scss">
.toFixed {
  background: #436aff;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: center;
  padding: 20px 25px;
  box-sizing: border-box;
  .header-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .chLogo {
      width: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .enLogo {
      width: 100px;
      height: 10px;
      margin-top: -10px;
    }
    .list {
      width: 20px;
      img {
        width: 100%;
        height: 100%;
      }
      .popup {
        .list-popup {
          height: 100%;
          width: 85%;
          padding: 0 30px;
          box-sizing: border-box;
          .content {
            margin-top: 65px;
            border-top: 1px solid #cccccc;
            padding: 40px 0;
            .language {
              color: #8a9196;
              font-size: 18px;
              white-space: nowrap;
              margin-bottom: 30px;
            }
            .tab {
              margin-bottom: 30px;
              transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -webkit-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              .tab-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
              .drop-item {
                overflow: hidden;
                display: block;
                padding-left: 20px;
                font-size: 14px;
                color: #8a9196;
                line-height: 32px;
                margin-top: 8px;
              }
              a {
                color: #8a9196;
                font-size: 18px;
                white-space: nowrap;
              }
              .el-icon--right {
                color: #8a9196;
              }
            }
          }
        }
      }
    }
  }
}
::v-deep .van-icon-cross::before {
  color: #000;
  font-size: 30px;
  margin-right: 10px;
}
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
.transition-box {
  margin-bottom: 10px;
  width: 200px;
  height: 100px;
  border-radius: 4px;
  background-color: #409eff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
</style>
