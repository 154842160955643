<template>
  <div class="footerBox">
    <div class="text">
      <div class="partLink">
        <div class="foots">
          <div>
            <router-link :to="{ path: '/aboutUs' }">{{
              $t("lang.footer.titleOne")
            }}</router-link>
          </div>
          <div>
            <router-link :to="{ path: '/brandCenter' }">{{
              $t("lang.footer.titleThree")
            }}</router-link>
          </div>
        </div>
        <div class="foots">
          <div>
            <router-link :to="{ path: '/trade' }">{{
              $t("lang.footer.titleTwo")
            }}</router-link>
          </div>
          <div>
            <router-link :to="{ path: '/joinUs' }">{{
              $t("lang.footer.titleFour")
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-con">
      <div class="container">
        <div class="foterpic">
          <img
            :src="logo"
            :style="{ width: $i18n.locale === 'en-US' ? '100px' : '50px' }"
          />
        </div>
        <div class="footerWx">
          <img src="../assets/gongzong.png" style="margin-right: 20px" />
          <img src="../assets/map.png" />
        </div>
      </div>
      <div class="footer-item">
        <a href="https://beian.miit.gov.cn" target="_blank" class="link">
          2021 ©one-lime.com 沪ICP备2021022268号️-1
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import chaLogo from "@/assets/logo-1.jpg";
import enLogo from "@/assets/eng_footer.jpg";
export default {
  name: "pcFooter",
  data() {
    return {
      logo: "",
      chaLogo,
      enLogo,
    };
  },
  created() {
    const language = sessionStorage.getItem("language") || "zh-CN";
    if (language === "zh-CN") {
      this.logo = chaLogo;
    } else {
      this.logo = enLogo;
    }
  },
  watch: {
    "$i18n.locale": function (newVal) {
      if (newVal === "en-US") {
        this.logo = enLogo;
      } else {
        this.logo = chaLogo;
      }
    },
  },
};
</script>
<style lang="scss">
.footerBox {
  width: 100%;
  padding: 0 25px;
  background: #f6f6f6;
  box-sizing: border-box;
  a {
    text-decoration: none;
  }
  .text {
    padding: 40px 0;
    .partLink {
      margin: 0 auto;
      .foots {
        display: flex;
        justify-content: space-between;
      }
      .foots:nth-child(2) {
        margin-top: 40px;
      }
      div {
        a:active {
          border-bottom: 1px solid #000;
        }
        a {
          color: #333;
          cursor: pointer;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
  .footer-con {
    padding: 40px 0 20px;
    border-top: 1px solid #c4c4c4;
    .container {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    .footerWx {
      img {
        width: 40px;
      }
    }
    .footer-item {
      color: #7f7f7f;
      font-size: 14px;
      margin-top: 30px;
      text-align: center;
    }
  }
}
.link {
  color: #7f7f7f;
}
</style>
