<template>
  <div class="custmoerBox">
    <mobileHeader />
    <div class="custmbg">
      <img src="../../assets/m_custmobg.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.customerService.bannerTitle") }}
      </div>
    </div>
    <div class="custmDetail">
      <div class="custmdiv">{{ $t("lang.customerService.custmdiv") }}</div>
      <div class="part">
        <div class="part_left">
          <div class="title">{{ $t("lang.customerService.titleOne") }}</div>
          <div class="parps">{{ $t("lang.customerService.parpsOne") }}</div>
          <div class="title" style="margin-top: 40px">
            {{ $t("lang.customerService.titleTwo") }}
          </div>
          <div class="parps">{{ $t("lang.customerService.parpsTwo") }}</div>
        </div>
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "customerService",
  components: {
    mobileHeader,
    mobileFooter,
  },
};
</script>

<style scoped lang="scss">
.custmoerBox {
  .custmbg {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .custmDetail {
    padding: 0 25px;
    margin-top: 50px;
    margin-bottom: 50px;
    .custmdiv {
      font-size: 14px;
      color: #6d7276;
      line-height: 25px;
    }
    .part {
      display: flex;
      flex-direction: row;
      margin-top: 40px;
      .title {
        font-size: 20px;
        font-weight: bold;
      }
      .parps {
        font-size: 14px;
        line-height: 25px;
        color: #6d7276;
        margin-top: 10px;
      }
      .part_right {
        margin-left: 40px;
      }
    }
  }
}
</style>
